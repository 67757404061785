import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter)
import Layout from '../views/Layout/index.vue'
import Elayout from '../views/Layout/Eindex.vue'
import Home from '../views/Home/Home.vue'
import Ehome from '../views/Home/Ehome.vue'
import NotFound from '../views/Notfund/Notfund.vue'

const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '*',
            // redirect: '/404', // 放在最后，捕获所有未匹配的路由
            component: NotFound
        },
        {
            path: '',
            component: Layout,
            children: [
                {
                    path: '',
                    component: Home,
                    name: 'home',
                },
                // {
                //     path: '404',
                //     component: NotFound
                // },
                {
                    path: 'product/:name',
                    component: () => import('../views/Product/Product.vue'),
                    name: 'product',
                },
                {
                    path: 'multiline/:name',
                    component: () => import('../views/Product/Mproduct.vue'),
                    name: 'Mproduct',
                },
                {
                    path: 'download',
                    component: () => import('../views/Download/Download.vue'),
                    name: 'download',
                },
                {
                    path: 'solution/:name',
                    component: () => import('../views/Solution/Solution.vue'),
                    name: 'solution',
                },
                {
                    path: 'industry/:name',
                    component: () => import('../views/Industry/Industry.vue'),
                    name: 'industry',
                },
                {
                    path: 'about',
                    component: () => import('../views/About/About.vue'),
                    name: 'about',
                },
                {
                    path: 'contact',
                    component: () => import('../views/About/Contact.vue'),
                    name: 'contact',
                },
                {
                    name: 'series',
                    path: 'series',
                    component: () => import('../views/Series/Series.vue'),
                },
                {
                    name: 'module',
                    path: 'module/:name',
                    component: () => import('../views/Product/Module.vue'),
                },
                {
                    name: 'solid',
                    path: 'solid/:name',
                    component: () => import('../views/Product/Puresolid.vue'),
                },
                {
                    name: 'camera',
                    path: 'camera/:name',
                    component: () => import('../views/Product/Camera.vue'),
                },
            ]
        },
        {
            path: '/en',
            component: Elayout,
            children: [
                {
                    path: '',
                    component: Ehome,
                    name: 'Ehome',
                },
                {
                    path: 'product/:name',
                    component: () => import('../views/Product/Eproduct.vue'),
                    name: 'Eproduct',
                },
                {
                    path: 'multiline/:name',
                    component: () => import('../views/Product/Emproduct.vue'),
                    name: 'Emproduct',
                },
                {
                    path: 'download',
                    component: () => import('../views/Download/Edownload.vue'),
                    name: 'Edownload',
                },
                {
                    path: 'solution/:name',
                    component: () => import('../views/Solution/Esolution.vue'),
                    name: 'Esolution',
                },
                {
                    path: 'industry/:name',
                    component: () => import('../views/Industry/Eindustry.vue'),
                    name: 'Eindustry',
                },
                {
                    path: 'about',
                    component: () => import('../views/About/Eabout.vue'),
                    name: 'Eabout',
                },
                {
                    path: 'contact',
                    component: () => import('../views/About/Econtact.vue'),
                    name: 'Econtact',
                },
                {
                    name: 'series2',
                    path: 'series',
                    component: () => import('../views/Series/Eseries.vue')
                },
                {
                    name: 'Emodule',
                    path: 'module/:name',
                    component: () => import('../views/Product/Emodule.vue'),
                },
                {
                    name: 'Esolid',
                    path: 'solid/:name',
                    component: () => import('../views/Product/Epuresolid.vue'),
                },
                {
                    name: 'camera',
                    path: 'camera/:name',
                    component: () => import('../views/Product/Ecamera.vue'),
                },
            ]
        },

    ],
    scrollBehavior() {
        return {
            x: 0,
            y: 0
        }
    }
})

// router.beforeEach((to, from, next) => {
//     next()
// })


// function getRoutesList(routes, pre) {
//     return routes.reduce((array, route) => {
//         const path = `${pre}${route.path}`;

//         if (route.path !== '*') {
//             array.push(path);
//         }

//         if (route.children) {
//             array.push(...getRoutesList(route.children, `${path}/`));
//         }

//         return array;
//     }, []);
// }


// function getRoutesXML() {
//     const list = getRoutesList(router.options.routes, 'https://www.richbeam.com')
//         .map(route => `<url><loc>${route}</loc></url>`)
//         .join('\r\n');
//     return `<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.sitemaps.org/schemas/sitemap/0.9 http://www.sitemaps.org/schemas/sitemap/0.9/sitemap.xsd">
//       ${list}
//     </urlset>`;
// }

// console.log(getRoutesXML());


export default router
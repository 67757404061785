import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

const state = {
    // index和home
    indexDanList: [],
    indexDuoList: [],
    indexModule:[],
    indexSolid:[],
    // 事件相机
    indexCamera: [],
    indexInduList: [],
    indexSoluList: [],
    indexFooter: [],
    indexMatrix: [],
    homeContent: {},
    homeVideo: [],
    homeDigit: [],
    homeDanList: [],
    homeDuoList: [],
    homeSolution: [],
    homePartList: [],
    homeWhyList: [],
    homeSuccess: [],
    // eindex和ehome
    eindexDanList: [],
    eindexModule:[],
    eindexSolid:[],
    // 事件相机
    eindexCamera:[],
    eindexDuoList: [],
    eindexInduList: [],
    eindexSoluList: [],
    eindexFooter: [],
    eindexMatrix: [],
    ehomeContent: {},
    ehomeVideo: [],
    ehomeDigit: [],
    ehomeDanList: [],
    ehomeDuoList: [],
    ehomeSolution: [],
    ehomePartList: [],
    ehomeWhyList: [],
    ehomeSuccess: [],
    // 下载与支持
    downLoad: {},
    publicDown: [],
    edownLoad: {},
    epublicDown: [],
    // 企业简介
    aboutCompany: {},
    comCulture: [],
    aboutHistory: [],
    aboutAwards: [],
    aboutAgent: [],
    eaboutCompany: {},
    ecomCulture: [],
    eaboutHistory: [],
    eaboutAwards: [],
    eaboutAgent: [],
    // 联系我们
    contactPage: {},
    contactMaxtrix: [],
    contactOffice: [],
    econtactPage: {},
    econtactMaxtrix: [],
    econtactOffice: [],
    // 生产规模
    scaleObject: {},
    escaleObject: {},
}
const actions = {

}
const mutations = {
    // 存储index和home
    saveIndexDanList(state, value){
        state.indexDanList = value
    },
    saveIndexDuoList(state, value){
        state.indexDuoList = value
    },
    saveIndexModule(state, value){
        state.indexModule = value
    },
    saveIndexSolid(state, value){
        state.indexSolid = value
    },
    // 事件相机indexCamera
    saveIndexCamera(state, value){
        state.indexCamera = value
    },
    saveIndexInduList(state, value){
        state.indexInduList = value
    },
    saveIndexSoluList(state, value){
        state.indexSoluList = value
    },
    saveIndexFooter(state, value){
        state.indexFooter = value
    },
    saveIndexMatrix(state, value){
        state.indexMatrix = value
    },
    saveHomeContent(state, value){
        state.homeContent = value
    },
    saveHomeVideo(state, value){
        state.homeVideo = value
    },
    saveHomeDigit(state, value){
        state.homeDigit = value
    },
    saveHomeDanList(state, value){
        state.homeDanList = value
    },
    saveHomeDuoList(state, value){
        state.homeDuoList = value
    },
    saveHomeSolution(state, value){
        state.homeSolution = value
    },
    saveHomePartList(state, value){
        state.homePartList = value
    },
    saveHomeWhyList(state, value){
        state.homeWhyList = value
    },
    saveHomeSuccess(state, value){
        state.homeSuccess = value
    },
    // 存储eindex和ehome
    saveEindexDanList(state, value){
        state.eindexDanList = value
    },
    saveEindexDuoList(state, value){
        state.eindexDuoList = value
    },
    saveEindexModule(state, value){
        state.eindexModule = value
    },
    saveEindexSolid(state, value){
        state.eindexSolid = value
    },
    // 事件相机
    saveEindexCamera(state, value){
        state.eindexCamera = value
    },
    saveEindexInduList(state, value){
        state.eindexInduList = value
    },
    saveEindexSoluList(state, value){
        state.eindexSoluList = value
    },
    saveEindexFooter(state, value){
        state.eindexFooter = value
    },
    saveEindexMatrix(state, value){
        state.eindexMatrix = value
    },
    saveEhomeContent(state, value){
        state.ehomeContent = value
    },
    saveEhomeVideo(state, value){
        state.ehomeVideo = value
    },
    saveEhomeDigit(state, value){
        state.ehomeDigit = value
    },
    saveEhomeDanList(state, value){
        state.ehomeDanList = value
    },
    saveEhomeDuoList(state, value){
        state.ehomeDuoList = value
    },
    saveEhomeSolution(state, value){
        state.ehomeSolution = value
    },
    saveEhomePartList(state, value){
        state.ehomePartList = value
    },
    saveEhomeWhyList(state, value){
        state.ehomeWhyList = value
    },
    saveEhomeSuccess(state, value){
        state.ehomeSuccess = value
    },
    // 下载与支持
    saveDownLoad(state, value){
        state.downLoad = value
    },
    savePublicDown(state, value){
        state.publicDown = value
    },
    saveProfileDown(state, value){
        state.profileDown = value
    },
    saveEdownLoad(state, value){
        state.edownLoad = value
    },
    saveEpublicDown(state, value){
        state.epublicDown = value
    },
    saveEprofileDown(state, value){
        state.eprofileDown = value
    },
    // 企业简介
    saveAboutCompany(state, value){
        state.aboutCompany = value
    },
    saveComCulture(state, value){
        state.comCulture = value
    },
    saveAboutHistory(state, value){
        state.aboutHistory = value
    },
    saveAboutAwards(state, value){
        state.aboutAwards = value
    },
    saveAboutAgent(state, value){
        state.aboutAgent = value
    },
    saveEaboutCompany(state, value){
        state.aboutCompany = value
    },
    saveEcomCulture(state, value){
        state.comCulture = value
    },
    saveEaboutHistory(state, value){
        state.aboutHistory = value
    },
    saveEaboutAwards(state, value){
        state.aboutAwards = value
    },
    saveEaboutAgent(state, value){
        state.aboutAgent = value
    },
    // 联系我们
    saveContactPage(state, value){
        state.contactPage = value
    },
    saveContactMaxtrix(state, value){
        state.contactMaxtrix = value
    },
    saveContactOffice(state, value){
        state.contactOffice = value
    },
    saveEcontactPage(state, value){
        state.contactPage = value
    },
    saveEcontactMaxtrix(state, value){
        state.contactMaxtrix = value
    },
    saveEcontactOffice(state, value){
        state.contactOffice = value
    },
    // 生产规模
    saveSaleInfo(state, value){
        state.scaleObject = value
    },
    saveEsaleInfo(state, value){
        state.escaleObject = value
    }
}
export default new Vuex.Store({
    state,
    actions,
    mutations
})